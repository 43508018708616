import * as actions from "../actions/actionTypes";
import { initialBreadCrumb } from "../../../utils/constants";

const initialState = {
  unitData: null,
  defultFilter: null,
  starItemData: null,
  itemData: null,
  extensionData: null,
  defaultUnitData: null,
  breadCrumbsData: null,
  spinner: 0,
  corelationDocumentData: null,
  gradesAndStandardsData: null,
  itemBreakoutList: null,
  engineeringDesignData: null,
  searchItemData: null,
  selectedCorrelationData: null,
  isCorrelationUrl: false,
  previewItemData: null,
  externalResourceID: null,
  myLibraryData: null,
  lessonAccessData: null,
  lessonBlockType: null,
  lessonBlocksData: null,
};

export const staarReadyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STAAR_READY_DATA_SUCCESS:
      return { ...state, unitData: action.payload.data };

    case actions.GET_STAAR_PRACTICE_DATA_SUCCESS:
      return { ...state, starItemData: action.payload?.data || [] };

    case actions.GET_RCASSIGNMENT_DATA_SUCCESS:
      return { ...state, starItemData: action.payload?.data || [] };

    case actions.GET_ITEMS_DATA_SUCCESS:
      return { ...state, itemData: action.payload?.data || [] };

    case actions.GET_STAAR_RESOURCES_SUCCESS:
      return { ...state, extensionData: action.payload?.data.resources || [] };

    case actions.UPDATE_BREADCRUMBS_DATA:
      return { ...state, breadCrumbsData: action.payload };

    case actions.UPDATE_SPINNER_VALUE:
      return { ...state, spinner: action.payload };

    case actions.UPDATE_DEFAULT_UNIT_DATA:
      return { ...state, defaultUnitData: action.payload };

    default:
      return state;
  }
};
